import React from "react"
import styled from "styled-components"
import PageWrapper from "../components/PageWrapper"
import underlineImg from "../images/landing/underline-scribble.svg"

const TermsAndConditions = () => {
  return (
    <PageWrapper>
      <Header>
        <h1>Terms and Conditions</h1>
        <p>
          Conditions of service
          <span />
        </p>
      </Header>
      <Content>
        <div className="term">
          <p className="title">1 AGREEMENT BETWEEN USER AND Build My Book</p>
          <div className="paragraphs">
            <p>
              The Build My Book Web Site is comprised of various Web pages
              operated by Build My Book.
            </p>
            <p>
              The Build My Book Web Site is offered to you conditioned on your
              acceptance without modification of the terms, conditions, and
              notices contained herein. Your use of the Build My Book Web Site
              constitutes your agreement to all such terms, conditions, and
              notices.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">2 MODIFICATION OF THESE TERMS OF USE</p>
          <div className="paragraphs">
            <p>
              Build My Book reserves the right to change the terms, conditions,
              and notices under which the Build My Book Web Site is offered,
              including but not limited to the charges associated with the use
              of the Build My Book Web Site.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">3 LINKS TO THIRD PARTY SITES</p>
          <div className="paragraphs">
            <p>
              The Build My Book Web Site may contain links to other Web Sites
              (&quot;Linked Sites&quot;). The Linked Sites are not under the
              control of Build My Book and Build My Book is not responsible for
              the contents of any Linked Site, including without limitation any
              link contained in a Linked Site, or any changes or updates to a
              Linked Site. Build My Book is not responsible for webcasting or
              any other form of transmission received from any Linked Site.
              Build My Book is providing these links to you only as a
              convenience, and the inclusion of any link does not imply
              endorsement by Build My Book of the site or any association with
              its operators.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">4 NO UNLAWFUL OR PROHIBITED USE</p>
          <div className="paragraphs">
            <p>
              As a condition of your use of the Build My Book Web Site, you
              warrant to Build My Book that you will not use the Build My Book
              Web Site for any purpose that is unlawful or prohibited by these
              terms, conditions, and notices. You may not use the Build My Book
              Web Site in any manner which could damage, disable, overburden, or
              impair the Build My Book Web Site or interfere with any other
              party&apos;s use and enjoyment of the Build My Book Web Site. You
              may not obtain or attempt to obtain any materials or information
              through any means not intentionally made available or provided for
              through the Build My Book Web Sites.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">5 USE OF COMMUNICATION SERVICES</p>
          <div className="paragraphs">
            <p>
              The Build My Book Web Site may contain bulletin board services,
              chat areas, news groups, forums, communities, personal web pages,
              calendars, and/or other message or communication facilities
              designed to enable you to communicate with the public at large or
              with a group (collectively, &quot;Communication Services&quot;),
              you agree to use the Communication Services only to post, send and
              receive messages and material that are proper and related to the
              particular Communication Service. By way of example, and not as a
              limitation, you agree that when using a Communication Service, you
              will not:
            </p>
            <div className="list">
              <p>
                Defame, abuse, harass, stalk, threaten or otherwise violate the
                legal rights (such as rights of privacy and publicity) of
                others.
              </p>
              <p>
                Publish, post, upload, distribute or disseminate any
                inappropriate, profane, defamatory, infringing, obscene,
                indecent or unlawful topic, name, material or information.
              </p>
              <p>
                Upload files that contain software or other material protected
                by intellectual property laws (or by rights of privacy of
                publicity) unless you own or control the rights thereto or have
                received all necessary consents.
              </p>
              <p>
                Upload files that contain viruses, corrupted files, or any other
                similar software or programs that may damage the operation of
                another&apos;s computer.
              </p>
              <p>
                Advertise or offer to sell or buy any goods or services for any
                business purpose, unless such Communication Service specifically
                allows such messages.
              </p>
              <p>
                Conduct or forward surveys, contests, pyramid schemes or chain
                letters.
              </p>
              <p>
                Download any file posted by another user of a Communication
                Service that you know, or reasonably should know, cannot be
                legally distributed in such manner.
              </p>
              <p>
                Falsify or delete any author attributions, legal or other proper
                notices or proprietary designations or labels of the origin or
                source of software or other material contained in a file that is
                uploaded.
              </p>
              <p>
                Restrict or inhibit any other user from using and enjoying the
                Communication Services.
              </p>
              <p>
                Violate any code of conduct or other guidelines which may be
                applicable for any particular Communication Service.
              </p>
              <p>
                Harvest or otherwise collect information about others, including
                e-mail addresses, without their consent.
              </p>
              <p>Violate any applicable laws or regulations.</p>
            </div>
            <p>
              Build My Book has no obligation to monitor the Communication
              Services. However, Build My Book reserves the right to review
              materials posted to a Communication Service and to remove any
              materials in its sole discretion. Build My Book reserves the right
              to terminate your access to any or all of the Communication
              Services at any time without notice for any reason whatsoever.
            </p>
            <p>
              Build My Book reserves the right at all times to disclose any
              information as necessary to satisfy any applicable law,
              regulation, legal process or governmental request, or to edit,
              refuse to post or to remove any information or materials, in whole
              or in part, in Build My Book&apos;s sole discretion.
            </p>
            <p>
              Always use caution when giving out any personally identifying
              information about yourself or your children in any Communication
              Service. Build My Book does not control or endorse the content,
              messages or information found in any Communication Service and,
              therefore, Build My Book specifically disclaims any liability with
              regard to the Communication Services and any actions resulting
              from your participation in any Communication Service. Managers and
              hosts are not authorized Build My Book spokespersons, and their
              views do not necessarily reflect those of Build My Book.
            </p>
            <p>
              Materials uploaded to a Communication Service may be subject to
              posted limitations on usage, reproduction and/or dissemination.
              You are responsible for adhering to such limitations if you
              download the materials.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">
            6 MATERIALS PROVIDED TO Build My Book OR POSTED AT ANY Build My Book
            WEB SITE
          </p>
          <div className="paragraphs">
            <p>
              Build My Book does not claim ownership of the materials you
              provide to Build My Book (including feedback and suggestions) or
              post, upload, input or submit to any Build My Book Web Site or its
              associated services (collectively &quot;Submissions&quot;).
              However, by posting, uploading, inputting, providing or submitting
              your Submission you are granting Build My Book, its affiliated
              companies and necessary sublicensees permission to use your
              Submission in connection with the operation of their Internet
              businesses including, without limitation, the rights to: copy,
              distribute, transmit, publicly display, publicly perform,
              reproduce, edit, translate and reformat your Submission; and to
              publish your name in connection with your Submission.
            </p>
            <p>
              No compensation will be paid with respect to the use of your
              Submission, as provided herein. Build My Book is under no
              obligation to post or use any Submission you may provide and may
              remove any Submission at any time in Build My Book&apos;s sole
              discretion.
            </p>
            <p>
              By posting, uploading, inputting, providing or submitting your
              Submission you warrant and represent that you own or otherwise
              control all of the rights to your Submission as described in this
              section including, without limitation, all the rights necessary
              for you to provide, post, upload, input or submit the Submissions.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">7 PRIVACY</p>
          <div className="paragraphs">
            <p>
              Our Privacy Policy governs all users of Build My Book. If you do
              not wish your personal data to be handled as specified in our
              Privacy Policy, then you must not use Build My Book. Users must
              not use the Services in connection with the sending of any
              unsolicited messages, or to harvest or otherwise collect
              information about Users, including email addresses, without their
              express consent. Users must not use any information about other
              Users that is accessible through the Services except as expressly
              permitted by these Terms or the relevant User.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">8 USER CONTENT AND CONTENT RULES</p>
          <div className="paragraphs">
            <p>
              Images and other material (including textual annotations to and
              comments on Images) uploaded, posted, contributed, distributed,
              communicated, transmitted or linked-to by a User on, through or in
              connection with Build My Book are treated as &quot;User
              Content&quot; for the purposes of these Terms. Users must have the
              right to deal, and the right to authorise us to deal, with their
              User Content in the ways that they and we use it in connection
              with the Services or for other purposes referred to in these
              Terms. You must not upload User Content, or otherwise deal with
              User Content through Build My Book, if you do not have the right
              to do so. Each User shall ensure that his/her User Content, and
              his/her and our use of it, does not:
            </p>
            <div className="list">
              <p>
                infringe anyone’s copyright; in particular, you must ensure that
                you either own the copyright in an Image that you upload to
                Build My Book or that you are fully licensed by the copyright
                owner to upload the Image and to make any subsequent use of it
                (such as by including the Image in a Product) or in email and
                postal communications we send to you);
              </p>
              <p>
                infringe anyone’s privacy or data protection rights; in
                particular, you must ensure you have the right to depict any
                person who appears in an Image that you upload to Build My Book
                and to authorise the subsequent use of it (such as by including
                a depiction of that person in a Product or in email and postal
                communications we send to you);
              </p>
              <p>
                infringe any other rights, such as a trade mark, of any person
                or entity or a duty owed to any person or entity, such as a duty
                of confidentiality;
              </p>
              <p>
                contravene any applicable law (including, without limitation,
                any criminal law) or regulation;
              </p>
              <p>
                include anything that is false, inaccurate, misleading,
                offensive, abusive, threatening or defamatory, or that might
                cause needless annoyance, inconvenience or distress to any
                person;
              </p>
              <p>
                contain any computer virus, macro virus, Trojan horse, worm, or
                anything else designed to interfere with, interrupt, or disrupt
                the normal operating procedures of a computer or to
                surreptitiously intercept, access without authority, or
                expropriate any system, data or personal information;
              </p>
              <p>
                misrepresent the User&apos;s identity in any way or impersonate
                any person;
              </p>
              <p>
                include any material containing personally identifying
                information about another person, such as their real name,
                address, phone number, or email address, except with the written
                approval of that person;
              </p>
              <p>
                contain material which is pornographic, obscene, indecent or
                offensive, which promotes discrimination based on race, sex,
                religion, nationality, disability, sexual orientation or age, or
                that may incite hatred or violence against any person or group;
              </p>
              <p>harass, upset, embarrass, alarm or annoy any person;</p>
              <p>
                give the impression that it emanates from us, if this is not the
                case;
              </p>
              <p>
                advocate, promote or assist any unlawful act such as (by way of
                example only) copyright infringement or computer misuse; or
              </p>
              <p>
                infringe our fair use requirements (see Clause 4 above) or
                otherwise interfere with the proper working of Build My Book.
              </p>
            </div>
            <p>The above are our Content Rules.</p>
            <p>
              Although we prohibit the uploading of Images and other User
              Content which infringes our Content Rules, we cannot control and
              do not monitor or pre-screen the use of our Site and App. It is
              possible that Images or other User Content may be accessible
              through our Site and App which contravenes our Content Rules. We
              are not responsible for such Images or User Content, but if you
              become aware of any such Images or User Content on our Site or App
              please contact us here.
            </p>
            <p>
              We may without notice delete any Image or other User Content which
              appears to breach our Content Rules, or prevent any use of
              Services (including purchase of Products) which includes any such
              Image or User Content.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">9 TERMS OF PURCHASE FOR PRODUCTS</p>
          <div className="paragraphs">
            <p className="bold">These terms of purchase apply to:</p>
            <p>
              Products which include an Image or other User Content uploaded or
              stored by you or another User – this is a “Personalised Product”;
              and Products which do not include such an Image or User Content -
              but may include an image of a painting, photograph or other
              artwork that we source and offer to Users for inclusion in our
              Products – these are “Non-personalised Products”. For
              completeness, an artwork that we source and offer to all Users for
              inclusion on Products does not count as an “Image” for the
              purposes of these Terms. Build My Book take no responsibility for
              content provided by the user that is used to create a personalised
              product.
            </p>
            <p>
              We have different rules for the cancellation of your purchase, and
              return of, Personalised Products and Non-personalised Products –
              see below.
            </p>
            <div className="list">
              <p className="bold">
                A contract between a User and Build My Book for the purchase of
                a Product is created as follows:
              </p>
              <p>
                The User places the order for a Product on the Site or App by
                pressing an order confirmation button at the end of the checkout
                process.
              </p>
              <p>
                By confirming your order, you are agreeing to purchase the
                Product you have selected
              </p>
              <p>
                At this point, we take payment for your order by means of your
                nominated Payment Method.
              </p>
              <p>
                We will send to you a “Thanks for your order” email detailing
                your order for the Product, and other information we must
                provide to you.
              </p>
              <p>
                When the Product you have ordered is shipped to you, we will
                send you a “dispatch” message.
              </p>
              <p>
                Note that Build My Book may reject an order in certain
                circumstances, namely:
              </p>
            </div>
            <div className="list">
              <p className="bold">
                Your order breaching the requirements of these Terms;
              </p>
              <p>The use of Images which infringe the Content Rules;</p>
              <p>
                The use of Images which are corrupted, unsupported technically
                or inadequately pixelated;
              </p>
              <p>The Product you order being unavailable;</p>
              <p>Our inability to obtain authorisation for your payment; or</p>
              <p>
                A relevant pricing or Product description error being
                identified.
              </p>
              <p>
                If we reject your order, we will contact you to confirm this and
                reverse the payment you have made for that order.
              </p>
            </div>
            <p>
              For orders that are accepted, unless we inform you otherwise in
              the order process, we will deliver the Product you have ordered
              within 30 days of the order.
            </p>
            <p>
              Your cancellation right for Non-personalised Products: You have
              the right to cancel your contract for the purchase of a
              Non-personalised Product, provided you notify us of the
              cancellation no longer than 14 days after the day following the
              day on which the Product is delivered.
            </p>
            <p>
              You must return the unused Non-personalised Product to us within
              14 days of notifying us of the cancellation, and must pay the cost
              of returning the Product to our nominated address using the form
              supplied with the product.
            </p>
            <p>
              We will refund the purchase price you have paid for the Product
              and its standard delivery charges (but not any expedited, tracked
              or other special delivery charges you may have chosen to pay)
              within 14 days of its return. However, if the value of the Product
              has been reduced by any handling of or dealing with it, beyond
              what is necessary to check whether the Product is as expected, we
              may deduct an amount to reflect that reduction in value from the
              sum we refund to you.
            </p>
            <p>
              No right of cancellation for Personalised Products: Note that the
              right to cancel a contract and return a Product, does not apply to
              Personalised Products, because they have been made to your
              specific requirements.
            </p>
            <p>
              Your statutory rights (Personalised and Non-Personalised
              Products): In addition to the cancellation rights for
              Non-Personalised Products (see above), you also have certain
              statutory rights in respect of both Personalised and
              Non-Personalised Products.
            </p>
            <p>
              These include that if the Product you have purchased is faulty, in
              that it is not as we describe it, of satisfactory quality or
              reasonably fit for purpose, then within 31 days of the day of its
              delivery you may exercise the right to a refund. And within six
              months of the date of delivery, and again only where the Product
              is faulty, you are entitled to a replacement, or a refund.
            </p>
            <p>
              Products should be returned in (or with) their original packaging
              by arrangement with our customer support team (see Clause 7)
            </p>
            <p>
              Delivery: We cannot guarantee that a specific carrier will be used
              when processing your order. In some cases we may use carriers that
              offer a comparable delivery service to that which you select. Your
              order despatch email will state the carrier used.
            </p>
          </div>
        </div>

        <div className="term">
          <p className="title">11 LIABILITY DISCLAIMER</p>
          <div className="paragraphs">
            <p>
              The information, software, products, and services included in or
              available through the Build My Book web site may include
              inaccuracies or typographical errors. Changes are periodically
              added to the information herein. Build My Book and/or its
              suppliers may make improvements and/or changes in the Build My
              Book web site at any time. Advice received via the Build My Book
              web site should not be relied upon for personal, medical, legal or
              financial decisions and you should consult an appropriate
              professional for specific advice tailored to your situation.
            </p>
            <p>
              Build My Book and/or its suppliers make no representations about
              the suitability, reliability, availability, timeliness, and
              accuracy of the information, software, products, services and
              related graphics contained on the Build My Book web site for any
              purpose. To the maximum extent permitted by applicable law, all
              such information, software, products, services and related
              graphics are provided &quot;as is&quot; without warranty or
              condition of any kind. Build My Book and/or its suppliers hereby
              disclaim all warranties and conditions with regard to this
              information, software, products, services and related graphics,
              including all implied warranties or conditions of merchantability,
              fitness for a particular purpose, title and non-infringement.
            </p>
            <p>
              To the maximum extent permitted by applicable law, in no event
              shall Build My Book and/or its suppliers be liable for any direct,
              indirect, punitive, incidental, special, consequential damages or
              any damages whatsoever including, without limitation, damages for
              loss of use, data or profits, arising out of or in any way
              connected with the use or performance of the Build My Book web
              site, with the delay or inability to use the Build My Book web
              site or related services, the provision of or failure to provide
              services, or for any information, software, products, services and
              related graphics obtained through the Build My Book web site, or
              otherwise arising out of the use of the Build My Book web site,
              whether based on contract, tort, negligence, strict liability or
              otherwise, even if Build My Book or any of its suppliers has been
              advised of the possibility of damages. Because some
              states/jurisdictions do not allow the exclusion or limitation of
              liability for consequential or incidental damages, the above
              limitation may not apply to you. If you are dissatisfied with any
              portion of the Build My Book web site, or with any of these terms
              of use, your sole and exclusive remedy is to discontinue using the
              Build My Book web site.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">12 TERMINATION/ACCESS RESTRICTION</p>
          <div className="paragraphs">
            <p>
              Build My Book reserves the right, in its sole discretion, to
              terminate your access to the Build My Book Web Site and the
              related services or any portion thereof at any time, without
              notice.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">13 GENERAL</p>
          <div className="paragraphs">
            <p>
              To the maximum extent permitted by law, this agreement is governed
              by the laws of the Republic of South Africa you hereby consent to
              the exclusive jurisdiction and venue of courts in the Republic of
              South Africa for all disputes arising out of or relating to the
              use of the Build My Book Web Site. Use of the Build My Book Web
              Site is unauthorized in any jurisdiction that does not give effect
              to all provisions of these terms and conditions, including without
              limitation this paragraph. You agree that no joint venture,
              partnership, employment, or agency relationship exists between you
              and Build My Book as a result of this agreement or use of the
              Build My Book Web Site. Build My Book&apos;s performance of this
              agreement is subject to existing laws and legal process, and
              nothing contained in this agreement is in derogation of Build My
              Book&apos;s right to comply with governmental, court and law
              enforcement requests or requirements relating to your use of the
              Build My Book Web Site or information provided to or gathered by
              Build My Book with respect to such use. If any part of this
              agreement is determined to be invalid or unenforceable pursuant to
              applicable law including, but not limited to, the warranty
              disclaimers and liability limitations set forth above, then the
              invalid or unenforceable provision will be deemed superseded by a
              valid, enforceable provision that most closely matches the intent
              of the original provision and the remainder of the agreement shall
              continue in effect. Unless otherwise specified herein, this
              agreement constitutes the entire agreement between the user and
              Build My Book with respect to the Build My Book Web Site and it
              supersedes all prior or contemporaneous communications and
              proposals, whether electronic, oral or written, between the user
              and Build My Book with respect to the Build My Book Web Site. A
              printed version of this agreement and of any notice given in
              electronic form shall be admissible in judicial or administrative
              proceedings based upon or relating to this agreement to the same
              extent and subject to the same conditions as other business
              documents and records originally generated and maintained in
              printed form. It is the express wish to the parties that this
              agreement and all related documents be drawn up in English.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">14 COPYRIGHT AND TRADEMARKS</p>
          <div className="paragraphs">
            <div className="list">
              <p>
                All contents of the Build My Book Web Site are: Copyright 2017
                by Build My Book and/or its suppliers. All rights reserved.
              </p>
              <p>
                The names of actual companies and products mentioned herein may
                be the trademarks of their respective owners.
              </p>
              <p>
                The example companies, organizations, products, people and
                events depicted herein are fictitious. No association with any
                real company, organization, product, person, or event is
                intended or should be inferred.
              </p>
              <p>Any rights not expressly granted herein are reserved.</p>
            </div>
          </div>
        </div>
        <div className="term">
          <p className="title">15 CONTACT</p>
          <div className="paragraphs">
            <p>
              We are a company registered in England and Wales under
              registration number 2005/032781/07 under the name Q-Photo Pty
              Limited; the address of our registered office is Shop 35 Parkview
              Shopping Center, corner Netcare and Garsfontein streets Moreleta
              Park. Our VAT registration number is 4340224650.
            </p>
          </div>
        </div>
        <div className="term">
          <p className="title">
            16 CONSENT FOR PROCESSING PERSONAL INFORMATION
          </p>
          <div className="paragraphs">
            <p>
              Consent for Processing Personal Information I understand that
              Build my Book requires certain Personal Information to be able to
              process my/our orders and consent to Build my Book processing and
              sharing this with third parties, where and only where this is
              necessary. Such third parties may include Aramex etc. Build my
              Book undertakes to only use Personal Information in accordance
              with the POPI Act.
            </p>
          </div>
        </div>
      </Content>
    </PageWrapper>
  )
}

const Header = styled.div`
  margin: 50px 0;
  text-align: center;
  position: relative;
  h1 {
    font-size: 1.5rem;
    font-family: ${props => props.theme.font.galanoBold};
    color: ${props => props.theme.color.grey};
  }
  p {
    font-size: 1.2rem;
    font-family: ${props => props.theme.font.saturdayRock};
  }
  span {
    background-image: url(${underlineImg});
    background-repeat: no-repeat;
    background-position: 50%;
    height: 8px;
    display: block;
    width: 100%;
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1.6rem;
      position: absolute;
      left: 50%;
      transform: rotate(-2deg);
    }
    span {
      transform: translateX(15px);
      background-position: right;
    }
  }
`

const Content = styled.div`
  padding-top: 50px;
  p {
    padding-bottom: 15px;
    color: ${props => props.theme.color.grey};
    &.bold {
      padding: 0;
      font-family: ${props => props.theme.font.galanoBold};
    }
    &.title {
      color: ${props => props.theme.color.dark};
      padding: 0;
      font-family: ${props => props.theme.font.galanoBold};
    }
  }
  .paragraphs {
    padding-bottom: 15px;
    .list {
      padding-left: 10px;
      padding-bottom: 15px;
      p {
        padding: 0;
        text-indent: -5px;
        &.bold {
          &::before {
            content: "";
          }
        }
        &::before {
          content: "-";
          text-indent: -5px;
        }
      }
    }
  }
`

export default TermsAndConditions
